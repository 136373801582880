<template>
  <div>
    <CyNotification
      class="mb-12"
      :title="$t('notification.title')"
      v-bind="{ content }"
      data-cy="notification"/>
    <p v-html="$sanitizeHtml($t('text.line1'))"/>
    <p v-html="$sanitizeHtml($t('text.line2'))"/>
    <p v-html="$sanitizeHtml($t('text.line3'))"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CyCloudCostManagementFormsLinkConfirmation',
  props: {
    credentialType: {
      type: String,
      required: true,
    },
    masterAccount: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('organization/cloudCostManagement', [
      'getProviderExtraInfoByCredType',
    ]),
    cloudProvider () {
      return _.$get(this.getProviderExtraInfoByCredType, this.credentialType, {})?.displayName
    },
    content () {
      const { masterAccount, cloudProvider } = this
      return this.$t('notification.message', { cloudProvider, masterAccount })
    },
  },
  i18n: {
    messages: {
      en: {
        notification: {
          title: '@:cloudCostManagement.groupBy.linkedAccount',
          message: 'Your {cloudProvider} account is linked to the master account managed by {masterAccount}.',
        },
        text: {
          line1: 'There is no need for you to configure the account. The only action you have to take is to <strong>confirm account creation</strong> and the system will do the rest for you.',
          line2: '<strong>Note</strong> that as your account is linked to the master account your credentials will have read access to the Cloud Cost Management data.',
          line3: 'Should you decide not to create a linked account you can either delete credentials you just added or cancel this operation by maintaining credentials not on the system for as long as you need.',
        },
      },
      es: {
        notification: {
          title: '@:cloudCostManagement.groupBy.linkedAccount',
          message: 'Su cuenta {cloudProvider} está vinculada a la cuenta maestra administrada por {masterAccount}.',
        },
        text: {
          line1: 'No es necesario que configure la cuenta. La única acción que debe realizar es <strong>confirmar la creación de la cuenta</strong> y el sistema hará el resto por usted.',
          line2: '<strong>Tenga en cuenta</strong> que, dado que su cuenta está vinculada a la cuenta maestra, sus credenciales tendrán acceso de lectura a los datos de Cloud Cost Management.',
          line3: 'Si decide no crear una cuenta vinculada, puede eliminar las credenciales que acaba de agregar o cancelar esta operación manteniendo las credenciales fuera del sistema durante el tiempo que necesite.',
        },
      },
      fr: {
        notification: {
          title: '@:cloudCostManagement.groupBy.linkedAccount',
          message: 'Votre compte {cloudProvider} est lié au compte principal géré par {masterAccount}.',
        },
        text: {
          line1: `Vous n'avez pas besoin de configurer le compte. La seule action que vous devez effectuer est de <strong>confirmer la création du compte</strong> et le système fera le reste pour vous.`,
          line2: `<strong>Notez</strong> que votre compte étant lié au compte principal, vos informations d'identification auront un accès en lecture seule aux données Cloud Cost Management.`,
          line3: `Si vous décidez de ne pas créer de compte lié, vous pouvez soit supprimer les informations d'identification que vous venez d'ajouter, soit annuler cette opération en conservant les informations d'identification absentes du système aussi longtemps que nécessaire.`,
        },
      },
    },
  },
}
</script>
