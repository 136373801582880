<template>
  <div class="layout__container d-flex pb-6">
    <div class="layout__content">
      <CyStepper
        class="stepper transparent"
        :value="accountCreationStep"
        elevation="0"
        sticky-footer>
        <div class="columns-container my-0">
          <div class="side-column"/>
          <div class="main-column">
            <v-stepper-header>
              <v-stepper-step
                :complete="accountCreationStep > 1"
                step="1">
                {{ $t('stepperSteps.credentials') }}
              </v-stepper-step>

              <v-divider/>

              <v-stepper-step
                :complete="accountCreationStep > 2"
                step="2">
                {{ $t('stepperSteps.configuration') }}
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <h3 class="stepper-title">
                  {{ _.isEmpty(credential) ? $t('createCredential') : $t('selectCredential') }}
                </h3>

                <CyNotification
                  v-if="isSwapMode"
                  :title="$t('cloudCostManagement.readonlyContent.title')"
                  :content="$t('cloudCostManagement.readonlyContent.message')"/>

                <CyNotification
                  theme="error"
                  :content="credentialErrors"/>

                <CyCredentials
                  v-if="isSwapMode"
                  class="required-field"
                  required
                  :value="_.get(credential, 'canonical')"
                  :error-messages="credentialsErrors"
                  :items="filteredCredentials"
                  :label="$t('cloudCostManagement.providerCredential')"
                  :icon-size="20"
                  @change="handleCredentialChange"/>

                <CyCloudCostManagementFormsCredentials
                  ref="credentialForm"
                  :credential="credential"
                  :disabled="loading"
                  :swap-mode="isSwapMode"
                  @is-valid="isFormValid = $event"
                  @type-changed="credentialType = $event"/>
              </v-stepper-content>

              <v-stepper-content step="2">
                <h3 class="stepper-title">
                  {{ $t('stepperSteps.configuration') }}
                </h3>

                <template v-if="isLinkedAccount">
                  <CyNotification
                    :content="parentAccountErrors"
                    theme="error"/>

                  <CyCloudCostManagementFormsLinkConfirmation
                    :credential-type="_.get(credential, 'type', '')"
                    master-account="Cycloid"/>
                </template>

                <template v-else>
                  <CyNotification
                    :content="accountErrors"
                    theme="error"/>

                  <CyCloudCostManagementFormsAccount
                    ref="formsAccount"
                    :credential-type="credentialType"
                    :disabled="loading || !$cycloid.permissions.canDisplay('CreateCloudCostManagementAccount')"
                    @is-valid="isFormValid = $event"
                    @change="accountFormData = $event"/>
                </template>
              </v-stepper-content>
            </v-stepper-items>
          </div>
          <div class="side-column">
            <CyCloudCostManagementAccountsSummary
              v-if="accountCreationStep > 1 && !!credential"
              :account-credential="credential"/>
          </div>
        </div>

        <template #footer>
          <CyButton
            variant="secondary"
            theme="primary"
            icon="close"
            @click="() => $router.push({ name: 'cloudCostManagementAccountsCredentials' })">
            {{ $t('forms.btnCancel') }}
          </CyButton>
          <CyButton
            v-if="accountCreationStep === 1"
            class="ml-5"
            :data-cy="!isSwapMode ? 'link-create-credentials' : ''"
            :disabled="!isFormValid"
            :loading="loading"
            theme="success"
            icon="chevron_right"
            @click="handleNextAction">
            {{ $t('forms.btnContinue') }}
          </CyButton>
          <CyButton
            v-else-if="accountCreationStep === 2 && isLinkedAccount"
            class="ml-5"
            :data-cy="'link-link-account'"
            :loading="loading"
            theme="success"
            icon="chevron_right"
            @click="handleNextAction">
            {{ $t('confirmLinkedAccountCreation') }}
          </CyButton>
          <CyButton
            v-else
            class="ml-5"
            data-cy="link-create-account"
            :disabled="!isFormValid"
            :loading="loading"
            theme="success"
            icon="done"
            @click="handleNextAction">
            {{ $t('forms.btnCreate') }}
          </CyButton>
        </template>
      </CyStepper>
    </div>
  </div>
</template>

<script>
import CyCloudCostManagementAccountsSummary from '@/components/cloud-cost-management/accounts/summary'
import CyCloudCostManagementFormsAccount from '@/components/cloud-cost-management/forms/account'
import CyCloudCostManagementFormsCredentials from '@/components/cloud-cost-management/forms/credentials'
import CyCloudCostManagementFormsLinkConfirmation from '@/components/cloud-cost-management/forms/link-confirmation'
import CyCredentials from '@/components/credentials'
import CyStepper from '@/components/stepper'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { credentialTypesAllowed, hasCloudCostManagementEB } from '@/utils/helpers/cloud-cost-management'
import { pagination as createAPIPage } from '@/utils/api'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageCloudCostManagementAccountsNew',
  components: {
    CyCloudCostManagementAccountsSummary,
    CyCloudCostManagementFormsAccount,
    CyCloudCostManagementFormsCredentials,
    CyCloudCostManagementFormsLinkConfirmation,
    CyCredentials,
    CyStepper,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.cloudCostManagementAccountsNew'), [
      {
        name: 'cloudCostManagementAccounts',
        label: this.$t('routes.cloudCostManagementAccounts'),
      },
      {
        name: 'cloudCostManagementSection',
        label: this.$t('routes.cloudCostManagementSection'),
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.cloudCostManagementAccountsNew'),
    }
  },
  beforeRouteLeave (to, _from, next) {
    if (to.name === 'cloudCostManagementAccountsCredentials' && this.accountCreationStep !== 1) {
      return this.backAction()
    }
    next()
  },
  data: () => ({
    accountCreationStep: 1,
    accountFormData: {},
    credentialType: '',
    loading: false,
    isFormValid: false,
    isSwapMode: false,
  }),
  computed: {
    ...mapState('alerts', {
      globalErrors: (state) => state.error,
    }),
    ...mapState('organization', {
      credentials: (state) => state.available.credentials,
      credentialsErrors: (state) => state.errors.credentials,
    }),
    ...mapState('organization/credential', {
      credential: (state) => state.detail,
      credentialErrors: (state) => state.errors,
    }),
    ...mapState('organization/cloudCostManagement', {
      accounts: (state) => state.accounts,
      accountErrors: (state) => state.errors.account,
      parentAccount: (state) => state.parentAccount,
      parentAccountErrors: (state) => state.errors.parentAccount,
    }),
    ...mapState('user', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('organization/cloudCostManagement', [
      'getProviderExtraInfoByCredType',
      'isLinkedAccount',
    ]),
    $static: () => ({
      credentialTypesAllowed,
    }),
    filteredCredentials () {
      const inUseCredentialCanonicals = _.map(this.accounts, ({ credential }) => credential?.canonical)
      return _.chain(this.credentials)
        .filter(({ type }) => _.includes(this.$static.credentialTypesAllowed, type))
        .filter(({ canonical, in_use: inUse }) => {
          return !hasCloudCostManagementEB(inUse.external_backends) && !_.includes(inUseCredentialCanonicals, canonical)
        })
        .value()
    },
  },
  watch: {
    accountCreationStep (newVal) {
      if (newVal === 2) {
        this.$refs.formsAccount.setupForm()
      }
    },
  },
  created () {
    const { credential } = this.$route.params
    if (!_.isEmpty(credential)) {
      this.SET_CREDENTIAL(credential)
      this.credentialType = credential.type
    }
  },
  async mounted () {
    const { credential } = this.$route.params
    if (!_.isEmpty(credential)) {
      this.$toggle.loading(true)
      await this.gotoConfigurationStep()
      this.$toggle.loading(false)
    }
  },
  destroyed () {
    this.clearErrors()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/cloudCostManagement', [
      'CREATE_ACCOUNT',
      'CREATE_CHILD_ACCOUNT',
      'GET_ACCOUNTS',
      'GET_PARENT_ACCOUNT',
    ]),
    ...mapActions('organization/credential', [
      'GET_CREDENTIAL',
      'CREATE_CREDENTIAL',
    ]),
    ...mapMutations('organization/cloudCostManagement', [
      'CLEAR_CE_ERRORS',
    ]),
    ...mapMutations('organization/credential', [
      'CLEAR_CREDENTIAL_ERRORS',
      'RESET_CREDENTIAL_STATE',
      'SET_CREDENTIAL',
    ]),
    clearErrors () {
      this.CLEAR_CREDENTIAL_ERRORS()
      this.CLEAR_CE_ERRORS('account')
      this.CLEAR_CE_ERRORS('parentAccount')
      this.RESET_CREDENTIAL_STATE()
    },
    async createAccount () {
      const credentialFormData = this.$refs.credentialForm.formData
      const engine = this.getProviderExtraInfoByCredType[credentialFormData.type].remoteTfStateEngine
      const configuration = { engine, ...this.accountFormData }

      const account = {
        external_backend: {
          configuration,
          credential_canonical: this.credential?.canonical ?? this.$getSlug(credentialFormData?.name),
          purpose: 'cost_explorer',
        },
      }

      await this.CREATE_ACCOUNT({ account, $router: this.$router })
    },
    async createChildAccount () {
      const { canonical: credentialCanonical } = this.credential
      const parentAccountId = this.parentAccount?.account_id
      const accountChild = { credential_canonical: credentialCanonical, parent_account_id: parentAccountId }

      await this.CREATE_CHILD_ACCOUNT({ accountChild, $router: this.$router })
    },
    async backAction () {
      const { canonical: credentialCanonical } = this.credential || {}

      this.$toggle.loading(true)
      this.accountCreationStep = 1
      this.clearErrors()

      await this.FETCH_AVAILABLE({ keyPath: 'credentials' })
      await this.GET_ACCOUNTS({ reqPage: createAPIPage(1, 100) })
      await this.GET_CREDENTIAL({ credentialCanonical })

      this.isFormValid = true
      this.isSwapMode = true
      this.$toggle.loading(false)
    },
    async createCredential () {
      const formData = this.$refs.credentialForm.formData
      const credential = {
        ...formData,
        owner: this.profile?.username,
        canonical: this.$getSlug(formData?.name),
      }

      await this.CREATE_CREDENTIAL({ credential })

      if (!_.isEmpty(this.globalErrors)) {
        this.RESET_CREDENTIAL_STATE()
        return
      }

      if (_.isEmpty(this.credentialErrors)) await this.gotoConfigurationStep()
    },
    async gotoConfigurationStep () {
      await this.GET_PARENT_ACCOUNT({ credentialCanonical: this.credential?.canonical })
      this.isSwapMode = false
      this.accountCreationStep++
    },
    async handleCredentialChange (credentialCanonical) {
      this.$toggle.loading(true)

      const { type } = this.credentials.find(({ canonical }) => canonical === credentialCanonical)
      await this.GET_CREDENTIAL({ credentialCanonical })

      this.credentialType = type
      this.$toggle.loading(false)
    },
    async handleNextAction () {
      const { accountCreationStep, isLinkedAccount, isSwapMode } = this

      this.$toggle.loading(true)

      if (isSwapMode) await this.gotoConfigurationStep()
      else if (accountCreationStep === 1) await this.createCredential()
      else if (accountCreationStep === 2) isLinkedAccount ? await this.createChildAccount() : await this.createAccount()

      this.$toggle.loading(false)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.cloudCostManagementAccountsNew',
        confirmLinkedAccountCreation: 'Confirm linked account creation',
        createCredential: 'Create credential',
        selectCredential: 'Select credential',
        stepperSteps: {
          configuration: '@:routes.cloudCostManagementAccounts',
          credentials: '@:routes.credentials',
        },
      },
      es: {
        title: '@:routes.cloudCostManagementAccountsNew',
        confirmLinkedAccountCreation: 'Confirmar creación de cuenta vinculada',
        createCredential: 'Crear credencial',
        selectCredential: 'Seleccionar credencial',
        stepperSteps: {
          configuration: '@:routes.cloudCostManagementAccounts',
          credentials: '@:routes.credentials',
        },
      },
      fr: {
        title: '@:routes.cloudCostManagementAccountsNew',
        confirmLinkedAccountCreation: 'Confirmer la création du compte lié',
        createCredential: 'Créer un identifiant',
        selectCredential: 'Sélectionnez un identifiant',
        stepperSteps: {
          configuration: '@:routes.cloudCostManagementAccounts',
          credentials: '@:routes.credentials',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .account-summary {
    position: relative;
    top: 104px;
    left: 33px;
  }

  .cy-details__actions__wrapper {
    text-align: right;
  }
}

.cy-credential ::v-deep {
  .v-select__selections > div {
    justify-content: flex-start;
  }

  .credential-icon {
    width: 25px;
  }
}

.layout {
  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .columns-container {
      display: flex;
      flex-direction: row;
      margin-top: 32px;
      margin-bottom: 32px;

      .main-column {
        width: 490px;
        min-width: 490px;

        .v-stepper__content {
          padding-right: 0;
          padding-left: 0;
        }

        .v-stepper__header {
          border: solid 1px get-color("primary", "light-4");
          border-radius: 4px;
          background-color: get-color("primary", "light-5");
        }
      }

      .side-column {
        width: 100%;
      }

      @media screen and (max-width: 1100px) {
        .side-column:first-child {
          flex-shrink: 2;
          min-width: 50px;
        }
      }

      .stepper-title {
        margin-bottom: 18px;
      }
    }
  }

  &__sidebar {
    padding-right: 18px;
    padding-left: 18px;
  }
}
</style>
